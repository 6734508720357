<template>
  <div>
    <b-alert variant="danger" v-if="error.length > 0" show>
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        Ocurrió un error al obtener los PromoTarjetas {{ error }}
      </div>
    </b-alert>

    <b-card no-body v-else>
      <b-card-header>
        <b-card-title>PromoTarjetas</b-card-title>
        <crear-modal :callback="getPromotarjetas" tipoPromo="PROTA" />
      </b-card-header>
      <b-card-body>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input type="text" class="d-inline-block" @input="onSearch" placeholder="Buscar" />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table mode="remote" :rows="rows" :columns="columns" :totalRows="totalRecords"
          :isLoading.sync="loading" @on-page-change="onPageChange" @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange" :sort-options="{
            enabled: false,
          }" :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }" :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }">
          <template slot="emptystate">No hay promotarjetas para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Fecha -->
            <span v-if="props.column.field === 'prFechaInicio'">
              <p class="mb-0">{{ formattedDate(props.row.prFechaInicio) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.prHoraInicio) }}</p>
            </span>
            <!-- Column: Fecha -->
            <span v-else-if="props.column.field === 'prFechaFin'">
              <p class="mb-0">{{ formattedDate(props.row.prFechaFin) }}</p>
              <p class="mb-0">{{ formattedHour(props.row.prHoraFin) }}</p>
            </span>
            <!-- Column: Estado -->
            <span v-else-if="props.column.field === 'prActivo'">
              <b-badge :variant="statusVariant(props.row.prActivo)">
                {{ statusText(props.row.prActivo) }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="editPromo($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDeletePromo($event, props.row)">
                    <feather-icon icon="AlertTriangleIcon" class="mr-50" />
                    <span>Desactivar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Otras Columnas -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Cantidad de PromoTarjetas </span>
                <b-form-select v-model="pageLength" :options="['10', '15', '20']" class="mx-1" @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                " />
              </div>
              <div>
                <b-pagination align="right" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })" :value="1" :per-page="pageLength"
                  last-number :total-rows="props.total" first-number prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>

      <b-modal title="Error" ok-only v-model="showErrorModal" centered ok-title="Accept" ok-variant="danger"
        modal-class="modal-danger">
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BAvatar,
  BSpinner,
  BCardBody,
  BDropdown,
  BCardText,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BFormSelect,
  BPagination,
  BCardHeader,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import CrearModal from "./CrearPromo.vue";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BAvatar,
    BSpinner,
    BCardBody,
    BDropdown,
    BCardText,
    CrearModal,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BCardHeader,
    VueGoodTable,
    BDropdownItem,
    ToastificationContent,
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      totalRecords: 0,
      seachTimeout: null,
      showErrorModal: false,
      columns: [
        {
          label: "Nombre",
          field: "prNombre",
        },
        {
          label: "Monto Mínimo",
          field: "prMontoMinimoCompra",
        },
        {
          label: "Monto Máximo",
          field: "prMontoMaximoCompra",
        },
        {
          label: "Cant. Max Promoción",
          field: "prCantidadMaximaPromocion",
        },
        {
          label: "Cant. usos por Cliente",
          field: "prCantidadUsoUsuario",
        },
        {
          label: "Inicio",
          field: "prFechaInicio",
        },
        {
          label: "Fin",
          field: "prFechaFin",
        },
        {
          label: "Estado",
          field: "prActivo",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    this.getPromotarjetas();
  },
  computed: {
    statusText() {
      const status = {
        true: "Activa",
        false: "Inactiva",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        false: "light-warning",
        true: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    onChangeFilters() {
      this.getPromotarjetas();
    },
    onSearch(query) {
      this.loading = true;
      this.searchTerm = query;
      clearTimeout(this.seachTimeout);
      this.seachTimeout = setTimeout(this.getPromotarjetas, 2500);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.getPromotarjetas();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      this.getPromotarjetas();
    },
    onSortChange(params) {
      this.sort = {
        type: params.sortType,
        field: this.columns[params.columnIndex].field,
      };
      this.getPromotarjetas();
    },
    onNewOrder(order) {
      this.rows.unshift(order);
    },
    formattedDate(originalDate) {
      return moment(new Date(originalDate)).format("DD/MM/YYYY");
    },
    formattedHour(originalHour) {
      return moment(originalHour, "HH:mm:ss").format("hh:mm a");
    },
    editPromo(event, promo) {
      event && event.preventDefault();
      this.$router.push({
        name: "promotarjeta-edit",
        params: { id: promo.prIdpromocion, promo: promo },
      });
    },
    confirmDeletePromo(event, promo) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea desactivar la PromoTarjeta?.", {
          size: "sm",
          title: "Confirmación",
          okTitle: "Desactivar",
          centered: true,
          okVariant: "warning",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deletePromo(promo);
        });
    },
    getPromotarjetas() {
      this.loading = true;
      return this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/ListaPromoTarjeta",
          body: JSON.stringify({
            PageNumber: this.page,
            RowsPerPage: this.pageLength,
            tokenSesion: getAuthToken(),
            searchQuery: this.searchTerm,
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.rows = res.data.lsPromociones;
            this.totalRecords = res.data.total;
          } else {
            this.error = res.data.mensaje;
            this.showErrorModal = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
          this.showErrorModal = true;
        });
    },
    deletePromo(promo) {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/EstatusPromocion",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
            IDPromocion: promo.prIdpromocion,
          }),
        })
        .then(async (res) => {
          if (res.data.bOk) {
            await this.getPromotarjetas();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se desactivó correctamente la PromoTarjeta`,
                title: "Éxito",
              },
              component: ToastificationContent,
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al eliminar la PromoTarjeta (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
table.vgt-table {
  font-size: 12px !important;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
